import * as React from 'react';
import { useState, useEffect } from 'react';
import Text from '../Text';
import styles from './Carousel.scss';
import { capitalize } from '../../../utils/capitalize';
import { useKeyPress } from '../../../hooks/useKeyPress';

interface Props {
	images: any[];
}

interface ImageSlideProps {
	url: string;
	caption?: string;
}

interface ArrowProps {
	direction: string;
	clickFunc: () => void;
	glyph: string;
}

const Carousel: React.FunctionComponent<Props> = ({ images }) => {
	const [ imgIndex, setImgIndex ] = useState(0);
	const leftArrow = useKeyPress(37);
	const rightArrow = useKeyPress(39);
	const lastIndex = images.length - 1;

	const prevSlide = () => {
		const shouldResetIndex = imgIndex === 0;
		shouldResetIndex ? setImgIndex(lastIndex) : setImgIndex(imgIndex - 1);
	};

	const nextSlide = () => {
		const shouldResetIndex = imgIndex === lastIndex;
		shouldResetIndex ? setImgIndex(0) : setImgIndex(imgIndex + 1);
	};

	useEffect(
		() => {
			if (leftArrow) {
				prevSlide();
			}
			if (rightArrow) {
				nextSlide();
			}
		},
		[ leftArrow, rightArrow ],
	);

	return (
		<section className={styles.carousel}>
			<Arrow direction="left" clickFunc={prevSlide} glyph="&#9664;" />

			<ImageSlide url={images[imgIndex]} />

			<Arrow direction="right" clickFunc={nextSlide} glyph="&#9654;" />

			<Text customStyle={styles.carouselContent}>
				{imgIndex + 1} / {images.length}
			</Text>
		</section>
	);
};

const ImageSlide: React.FunctionComponent<ImageSlideProps> = ({ url, caption }) => {
	return (
		<figure>
			<picture className={styles.imageSlide}>
				<img className={styles.carouselImage} src={url} />
			</picture>
			<figcaption>{caption}</figcaption>
		</figure>
	);
};

const Arrow: React.FunctionComponent<ArrowProps> = ({ direction, clickFunc, glyph }) => {
	const formattedDirection = capitalize(direction);
	const arrowDirection = 'carouselArrow' + formattedDirection;

	return <div className={styles[arrowDirection]} onClick={clickFunc} />;
};

export default Carousel;
