import * as React from 'react';
import InitContext from '../context/init';

export default function useCdn() {
    const { cdnUrl } = React.useContext(InitContext);

    return {
        requestCdn: src => `${cdnUrl}${src}`,
    };
}
