import * as React from 'react';
import styles from './Tag.scss';
import joinClassNames from '../../../utils/join-class-names';
import { ColorSchemeProp } from '~source/types/color-scheme';

interface Props {
	color?: ColorSchemeProp;
	fontSize?: 12 | 14 | 16 | 18;
	className?: string;
	label: string;
}

const Tag: React.FunctionComponent<Props> = ({ color = 'primary', fontSize = 16, className = '', label }) => {
	return (
		<div className={joinClassNames(styles.root, styles[color])}>
			<span className={joinClassNames(styles.label, className)} style={{ fontSize: `${fontSize}px` }}>
				{label}
			</span>
		</div>
	);
};

export default Tag;
