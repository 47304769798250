interface NavItem {
	id: string;
	slug: string;
	title: string;
}

export default class NavigationMenu {
	headerNavigation: NavItem[];

	constructor(data) {
		this.headerNavigation = data;
	}
}
