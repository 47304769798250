import * as React from 'react';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import styles from './Filter.scss';
import { Card, Text, Select, ToggleButton } from '~source/ui/components';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-dates';
import '../../../../styles/DatePicker_overrides.css';

interface Values {
	name: string;
}
export interface Props {
	teams: Values[];
	competition: Values[];
}


const elementIdStartDate = 'ET_startdate';
const elementIdEndDate = 'ET_enddate';
const openDirectionUp = 'up';
const openDirectionDown = 'down';
const open = 'ET_enddate';

function getFocusedInputElement(focusedInput) {
	const isStartDate = focusedInput === 'startDate';
	const isEndDate = focusedInput === 'endDate';

	if (!isStartDate && !isEndDate) {
		return null;
	}

	// We know that one of isStartDate or isEndDate is true
	const elementId = isStartDate ? elementIdStartDate : elementIdEndDate;

	return document.getElementById(elementId);
}

const DesktopFilter: React.FunctionComponent<Props> = props => {
    const scrollAnchor = React.useRef<HTMLDivElement>(null);
    const [scrollOverflow, setScrollOverflow] = React.useState<Boolean>(false);
    const [checked, setChecked]: any = React.useState(false);
    const [
        dateRangeOpenDirection,
        setDateRangeOpenDirection,
    ]: any = React.useState(openDirectionDown);
    const { t } = useTranslation();

    const { teams, competition } = props;

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [focusedInput, setFocusedInput] = React.useState(null);

    // Hook to window scroll event, debounce it by 100ms
    useWindowScrollPosition({
        throttle: 100,
    });

    const toggleCheckboxChange = event => {
        return setChecked(event.target.checked);
    };

    const updateDate = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        return;
    };

    const calculateCalendarOpenDirection = (focusedInput: string) => {
        const element = getFocusedInputElement(focusedInput);

        if (!element) {
            return;
        }

        const rect = element.getBoundingClientRect();
        const { innerHeight } = window;
        const halfWindowHeight = innerHeight / 2;
        const canFitInTopHalf = rect.top >= halfWindowHeight;

        setDateRangeOpenDirection(
            canFitInTopHalf ? openDirectionUp : openDirectionDown,
        );
    };

    React.useEffect(() => {
        // Set overflow state based on current offset of scrollAnchor element
        if (scrollAnchor !== null && scrollAnchor.current !== null) {
            const rect = scrollAnchor.current.getBoundingClientRect();
            const shouldScrollOverflow = rect.top === 0;

            if (
                (shouldScrollOverflow && !scrollOverflow) ||
                (!shouldScrollOverflow && scrollOverflow)
            ) {
                setScrollOverflow(shouldScrollOverflow);
            }
        }
    });

    return (
        <div className={styles.filter} ref={scrollAnchor}>
            <Card customStyles={styles.stickyWrapper}>
                <div className={styles.desktopFilter}>
                    <Text size="h3" customStyle={styles.headerText}>
                        {t('filter.dateFilter')}
                    </Text>
                    <div className={styles.datepickerWrapper}>
                        <DateRangePicker
                            appendToBody
                            startDate={startDate}
                            startDateId={elementIdStartDate}
                            endDate={endDate}
                            endDateId={elementIdEndDate}
                            displayFormat="DD/MM/YYYY"
                            disableScroll={false}
                            openDirection={dateRangeOpenDirection}
                            startDatePlaceholderText={t('filter.calendar.from')}
                            endDatePlaceholderText={t('filter.calendar.till')}
                            onDatesChange={({ startDate, endDate }) =>
                                updateDate({ startDate, endDate })
                            }
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => {
                                calculateCalendarOpenDirection(focusedInput);
                                setFocusedInput(focusedInput);
                            }}
                        />
                    </div>
                    <Text size="h3" customStyle={styles.headerText}>
                        {t('filter.priceFilter')}
                    </Text>
                    <Select
                        customStyles={styles.selectWidth}
                        options={[
                            t('select.price.lowToHigh'),
                            t('select.price.highToLow'),
                        ]}
                    />
                    <Text size="h3" customStyle={styles.headerText}>
                        {t('filter.travelTimeFilter')}
                    </Text>
                    <Select
                        customStyles={styles.selectWidth}
                        options={[
                            t('select.days.optionOne'),
                            t('select.days.optionTwo'),
                            t('select.days.optionThree'),
                        ]}
                    />
                    <Text size="h3" customStyle={styles.headerText}>
                        {t('filter.oponentFilter')}
                    </Text>
                    {teams.map(team => {
                        return (
                            <ToggleButton
                                key={team.name}
                                name={team.name}
                                toggle={() => setChecked(!checked)}
                            />
                        );
                    })}
                    <Text size="h3" customStyle={styles.headerText}>
                        {t('filter.competitionFilter')}
                    </Text>
                    {competition.map(competition => {
                        return (
                            <ToggleButton
                                key={competition.name}
                                name={competition.name}
                                toggle={() => setChecked(!checked)}
                            />
                        );
                    })}
                </div>
            </Card>
        </div>
    );
};

export default DesktopFilter;
