import { ContactType } from '~source/types/contact';

export default function getHrefFromType(type: ContactType, to: string) {
	switch (type) {
		case 'WhatsApp':
			return `https://api.whatsapp.com/send?phone=${to.replace(
				/[^0-9]/g,
				'',
			)}`;
		case 'Email':
			return `mailto:${to}`;
		case 'Phone':
			return `tel:${to}`;
		default:
			return to;
	}
}
