import * as React from 'react';
import PageResolver from './views/pages/PageResolver';

export const paths = {
	home: '/',
	notFound: '/404',
};

type Route = {
	path: string;
	component: React.ComponentType;
	title?: string;
	meta?: {
		name: string;
		content: string;
	}[];
	exact: boolean;
	html?: string;
};

export let routes: Route[] = [
	{
		path: '/:slug?',
		component: PageResolver,
		title: 'Page',
		exact: true,
	},
];
