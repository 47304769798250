import * as React from 'react';
import getHrefFromType from '../../../utils/get-href-from-type';
import joinClassNames from '../../../utils/join-class-names';
import styles from './ContactLink.scss';
import { ContactType } from '~source/types/contact';
import { PositionType } from '~source/types/position';

interface Props {
	size?: number;
	type: ContactType;
	to: string;
	position?: PositionType;
}

function getFontAwesomeNameFromType(type: ContactType): string {
	switch (type) {
		case 'WhatsApp':
			return 'whatsappIcon';
		case 'Email':
			return 'envelopeIcon';
		case 'Phone':
			return 'phoneIcon';
		default:
			return '';
	}
}

const ContactLink: React.FunctionComponent<Props> = ({
	size = 35,
	type,
	to = '',
	position = 'above',
}) => {
	const faIconName = getFontAwesomeNameFromType(type);
	return (
		<a
			className={joinClassNames(
				styles.root,
				type === 'WhatsApp' && styles.whatsapp,
				styles[faIconName],
				styles[position],
			)}
			href={getHrefFromType(type, to)}
			target="_blank"
			rel="noopener noreferrer"
			style={{ width: `${size}px`, height: `${size}px` }}
		>
			<span
				className={joinClassNames(styles.to, styles[faIconName])}
				style={{ lineHeight: `${size}px` }}
			>
				{to}
			</span>
		</a>
	);
};

export default ContactLink;
