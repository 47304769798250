import * as React from 'react';
import { Link } from 'react-router-dom';

import { Container, ModuleTitle, Html, TrustBox } from '~source/ui/components';
import { TRUST_PILOT_TEMPLATE_ID_CAROUSEL } from '~source/ui/components/TrustBox/TrustBox';
import { ModuleFieldsTrustpilot } from '~source/core/interfaces';
import LinkModel from '~source/core/models/link';
import s from './TrustpilotModule.scss';

interface Props {
    fields: ModuleFieldsTrustpilot;
}

const TrustpilotModule: React.FunctionComponent<Props> = ({
    fields: { link, subtitle, title, trustpilotTitle },
}) => (
    <Container className={s.root} isTransparent>
        <ModuleTitle>{title}</ModuleTitle>
        <div className={s.subTitle}>{subtitle}</div>
        <Html className={s.trustpilotTitle}>{trustpilotTitle}</Html>
        <TrustBox
            className={s.trustBox}
            width="100%"
            templateId={TRUST_PILOT_TEMPLATE_ID_CAROUSEL}
        />
        {link.map((l: LinkModel) => (
            <Link className={s.link} to={l.url} key={l.name}>
                {l.name}
            </Link>
        ))}
    </Container>
);

export default TrustpilotModule;
