import * as React from 'react';

interface Props {
	className?: string;
	color?: string;
	height?: number;
	width?: number;
}

const renderShirtStroke = (color: string) => {
	return color === '#FFFFFF' || color === '#F1F1F1' ? '#000' : 'none';
};

const Shirt: React.FunctionComponent<Props> = ({
	className,
	color = '#ccc',
	height = 33,
	width = 43,
}) => {
	return (
		<svg
			className={className}
			width={`${width}px`}
			height={`${height}px`}
			viewBox={`0 0 43 33`}
			version="1.1"
		>
			<g
				id={`Shirt-${color}`}
				stroke={renderShirtStroke(color)}
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<path
					fill={color}
					d="M20.6860468,4.20571613 C20.6862366,4.19955175 20.6864264,4.19338637 20.6866162,4.18722001 C20.8421596,4.19821384 20.9961968,4.20527786 21.148728,4.20841206 C21.3012592,4.20527786 21.4552965,4.19821384 21.6108398,4.18722001 C21.6110297,4.19338637 21.6112195,4.19955175 21.6114092,4.20571613 C24.2869677,4.11810668 26.4798427,2.77103962 28.1900342,0.164514928 C28.7266552,-0.653356166 33.4291292,1.57825191 42.297456,6.85933915 L36.7259228,14.1530403 L31.7337353,11.0976204 L31.7337353,30.4113411 C30.2657572,31.8662199 27.1075122,32.6941801 22.2590001,32.8952217 C22.2532977,32.9271534 22.246961,32.943106 22.2399902,32.9430794 C21.8680216,32.941661 21.5042675,32.9371958 21.148728,32.9296839 C20.7931885,32.9371958 20.4294345,32.941661 20.0574658,32.9430794 C20.050495,32.943106 20.0441584,32.9271534 20.0384559,32.8952217 C15.1899439,32.6941801 12.0316988,31.8662199 10.5637207,30.4113411 L10.5637207,11.0976204 L5.5715332,14.1530403 L3.55271368e-15,6.85933915 C8.86832682,1.57825191 13.5708008,-0.653356166 14.1074219,0.164514928 C15.8176133,2.77103962 18.0104883,4.11810668 20.6860468,4.20571613 Z"
					id="Combined-Shape"
				/>
			</g>
		</svg>
	);
};

export default Shirt;
