import * as React from 'react';
import { hot } from 'react-hot-loader';
import {
    BrowserRouter,
    matchPath,
    Redirect,
    withRouter,
} from 'react-router-dom';
import { paths, routes } from './routes';
import useApi from '~source/hooks/useApi';
import useEndpoint from '~source/hooks/useEndpoint';
import './styles.scss';

// import i18n (needs to be bundled ;))
import './i18n';
import UspList from './components/UspList/UspList';
import Navbar from './components/Navbar/Navbar';
import { HeaderTopRow, Hamburger, Footer, LoadScreen } from './components';
import InitContext from '~source/context/init';
import InitModel from '~source/core/models/init';

function AppContainer({ children }) {
    return <BrowserRouter>{children}</BrowserRouter>;
}

const App = withRouter(({ location }) => {
    const route =
        routes.find(route => matchPath(location.pathname, route)) || null;
    const { createCmsEndpoint } = useEndpoint();
    const initRequest = createCmsEndpoint('initialize');
    const [initResponse] = useApi(initRequest, 'GET');
    const [isLoading, setIsLoading] = React.useState(true);
    const [navbarIsOpen, setNavbar] = React.useState(false);
    const [initData, setInitData] = React.useState(new InitModel({}));

    React.useEffect(() => {
        if (initResponse) {
            setInitData(new InitModel(initResponse));
            setIsLoading(false);
        }
    }, [initResponse]);

    if (isLoading) {
        return <LoadScreen />;
    }

    if (route) {
        return (
            <InitContext.Provider value={initData}>
                <UspList />
                <Hamburger item={navbarIsOpen} setItem={setNavbar} />
                <HeaderTopRow />
                <Navbar navbarIsOpen={navbarIsOpen} setNavbar={setNavbar} />
                <main className={navbarIsOpen ? 'no-scroll' : ''}>
                    <route.component />
                </main>
                <Footer />
            </InitContext.Provider>
        );
    }

    return <Redirect to={paths.notFound} />;
});

export default hot(module)(() => (
    <AppContainer>
        <React.Suspense fallback="loading">
            <App />
        </React.Suspense>
    </AppContainer>
));
