import * as React from 'react';
import styles from './Text.scss';
import joinClassNames from '../../../utils/join-class-names';

interface Props {
    children: React.ReactType | React.ComponentType | React.ReactNode;
    size?: string;
    weight?: string;
    icon?: string;
    customStyle?: string;
    textStyle?: string;
    iconSpacing?: boolean;
}

const renderHeader = (children, size, customStyle, textStyle): JSX.Element => {
    switch (size) {
        case 'h1':
            return (
                <h1 className={joinClassNames(customStyle, textStyle)} data-testid="ui-text-h1">
                    {children}
                </h1>
            );
        case 'h2':
            return (
                <h2 className={joinClassNames(customStyle, textStyle)} data-testid="ui-text-h2">
                    {children}
                </h2>
            );
        case 'h3':
            return (
                <h3 className={joinClassNames(customStyle, textStyle)} data-testid="ui-text-h3">
                    {children}
                </h3>
            );
        case 'h4':
            return (
                <h4 className={joinClassNames(customStyle, textStyle)} data-testid="ui-text-h4">
                    {children}
                </h4>
            );
        case 'p':
            return (
                <p className={joinClassNames(customStyle, textStyle)} data-testid="ui-text-hp">
                    {children}
                </p>
            );
        default:
            return (
                <p className={joinClassNames(customStyle, textStyle)} data-testid="ui-text-default">
                    {children}
                </p>
            );
    }
};

const Text: React.FunctionComponent<Props> = ({
    children,
    size,
    customStyle,
    textStyle,
    iconSpacing = false,
}) => {
    return (
        <div className={joinClassNames(styles.text, iconSpacing && styles.iconSpacing)}>
            {renderHeader(children, size, customStyle, textStyle)}
        </div>
    );
};

export default Text;
