import * as React from 'react';
import styles from './UspList.scss';
import joinClassNames from '../../../utils/join-class-names';
import InitContext from '~source/context/init';

interface Props {
	mustIncludeIcons?: boolean;
	customStyle?: string;
}

const UspList: React.FunctionComponent<Props> = ({
	mustIncludeIcons = false,
	customStyle = '',
}) => {
	const { usps } = React.useContext(InitContext);

	const renderUsp = () => {
		return !usps
			? null
			: usps.data.map((usp: string) => {
					return (
						<li
							key={usp}
							className={joinClassNames(styles.item, styles[usp])}
						>
							<span
								className={joinClassNames(
									styles.label,
									styles.uspIcon,
								)}
							>
								{usp}
							</span>
							<div className={styles.line}> </div>
						</li>
					);
				});
	};

	return (
		<ul
			className={joinClassNames(
				styles.root,
				mustIncludeIcons && styles.isUsingIcons,
				customStyle,
			)}
		>
			{renderUsp()}
		</ul>
	);
};

export default UspList;
