import axios from 'axios';
import PreRenderService from '~source/core/services/prerender.service';

export interface Body {
    [key: string]:
        | Body
        | Body[]
        | string
        | string[]
        | boolean
        | number
        | number[];
}

function toUrlEncoded(body: Body) {
    return Object.keys(body)
        .map(
            k =>
                `${encodeURIComponent(k)}=${encodeURIComponent(
                    body[k].toString(),
                )}`,
        )
        .join('&');
}

interface FetchUrlParams {
    endpoint: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: Body;
    userToken?: string;
}

export async function fetchUrl<Entity>({
    endpoint,
    method = 'GET',
    body,
}: FetchUrlParams): Promise<Entity> {
    const params = {
        method,
        data: body,
        // headers: (() => {
        //     const headers: any = {};
        //     headers.Authorization = `Bearer ${API_TOKEN}`;
        //     headers['Accept-Language'] = 'nl_NL';
        //     return headers;
        // })(),
    };

    const response = await axios(endpoint, params);

    PreRenderService.checkResponse(response, endpoint);

    return response.data as Entity;
}
