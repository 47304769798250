const isPagesCall = endpoint => endpoint.indexOf('/pages/') !== -1;
const isEventsCall = endpoint =>
    endpoint.indexOf('/packages/football/events') !== -1;
const isOverViewPage = contentType => contentType === 'overviewPage';

class PreRenderService {
    isCallingOverviewPage: boolean;

    constructor() {
        this.isCallingOverviewPage = false;
    }

    checkResponse(response, endpoint) {
        // When variable is set or variable is not defined, do not continue
        if (
            window.prerenderReady === true ||
            typeof window.prerenderReady === 'undefined'
        )
            return;

        if (isPagesCall(endpoint)) {
            if (response.data && response.data.system) {
                const {
                    data: { system },
                } = response;

                // Not an overview page? Let the prerender save the rendered HTML
                if (!isOverViewPage(system.contentType)) {
                    window.prerenderReady = true;
                } else {
                    this.isCallingOverviewPage = true;
                }
            }
        }

        // Is an overview page? Let the prerender save the rendered HTML
        if (this.isCallingOverviewPage && isEventsCall(endpoint)) {
            window.prerenderReady = true;
        }
    }
}

const prerender = new PreRenderService();

export default prerender;
