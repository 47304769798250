import * as React from 'react';
import styles from './TrustBox.scss';
import { useTranslation } from 'react-i18next';
import joinClassNames from '../../../utils/join-class-names';
import { TRUST_PILOT_SHOW_STARS } from '../../../../__mocks__/mocks';

export const TRUST_PILOT_TEMPLATE_ID_CAROUSEL = '53aa8912dec7e10d38f59f36';
export const TRUST_PILOT_TEMPLATE_ID_MINI = '53aa8807dec7e10d38f59f32';
export const TRUST_PILOT_BUSINESS_UNIT_ID = '54ddb52c0000ff00057d635c';

interface Props {
    className?: string;
    templateId: string; // Template to use
    businessUnitId?: string; // Reviews belong to a certain business
    locale?: string; // Language to display
    width?: string; // Width of box
    height?: string; // Height of box
    theme?: string; // 'light' or 'dark'
    stars?: string; // Stars to display, e.g.: '3,4,5' does not display 1 and 2 star reviews
    tag?: string; // Reviews which contain a specific tag, e.g.: 'fc barcelona'
}

const TrustBox: React.FunctionComponent<Props> = ({
    className,
    templateId,
    businessUnitId = TRUST_PILOT_BUSINESS_UNIT_ID,
    locale,
    width,
    height,
    theme,
    stars = TRUST_PILOT_SHOW_STARS,
    tag,
}) => {
    // Create a reference to the <div> element which will represent the TrustBox
    const { t, i18n } = useTranslation();
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <div
            ref={ref} // We need a reference to this element to load the TrustBox in the effect.
            className={joinClassNames(styles.root, className)}
            data-template-id={templateId}
            data-businessunit-id={businessUnitId}
            data-locale={locale || i18n.language} // use locale prop instead of default lang
            data-style-width={width}
            data-style-height={height}
            data-theme={theme}
            data-stars={stars}
            data-tag={tag}
            style={{ height, width }}
        />
    );
};
export default TrustBox;
