interface Props {
    startTime: Date;
    endTime: Date;
}

export function isTimeWithinTimeFrame({ startTime, endTime }: Props) {
    const currentDate = new Date();
    const currentTotalMinutes =
        currentDate.getHours() * 60 + currentDate.getMinutes();
    const minMinutes = startTime.getUTCHours() * 60 + startTime.getUTCMinutes();
    const maxMinutes = endTime.getUTCHours() * 60 + endTime.getUTCMinutes();

    return (
        currentTotalMinutes >= minMinutes && currentTotalMinutes <= maxMinutes
    );
}
