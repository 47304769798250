import * as React from 'react';
import styles from './Hamburger.scss';
import joinClassNames from '../../../utils/join-class-names';

interface Props {
    item: boolean;
    setItem: (arg1: boolean) => void;
}

const Hamburger: React.FunctionComponent<Props> = ({ item, setItem }) => {
    return (
        <div
            className={joinClassNames(
                styles.toggle,
                item ? styles.toggleClose : styles.toggleOpen
            )}
            onClick={() => setItem(!item)}
        ></div>
    );
};

export default Hamburger;
