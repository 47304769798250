import * as React from 'react';

import joinClassNames from '~source/utils/join-class-names';
import { Spinner } from '../index';
import s from './LoadScreen.scss';

interface Props {
    isAbsolute?: boolean;
    isFixed?: boolean;
    text?: string;
}

const LoadScreen: React.FunctionComponent<Props> = ({
    isAbsolute = true,
    isFixed = false,
    text,
}) => {
    return (
        <div
            className={joinClassNames(
                s.root,
                isAbsolute && s.isAbsolute,
                isFixed && s.isFixed,
            )}
            data-testid="ui-load-screen"
        >
            <div className={s.container}>
                <Spinner className={s.spinner} />
                {text && <div className={s.text}>{text}</div>}
            </div>
        </div>
    );
};

export default LoadScreen;
