import Module from './Module';
import Seo from './seo';
import System from './system';
import { OverviewType } from '~source/types/page-overview';

export default class Page {
	contentType: string;
	modules: Module[];
	overviewFor?: OverviewType;
	seo: Seo;
	slugUrl: string;
	system: System;
	title: string;
	type: string;

	constructor(data: any = {}) {
		const { fields = {}, system = {} } = data;
		this.seo = new Seo(fields);
		this.modules =
			fields.modules && fields.modules.map((m) => new Module(m));
		this.overviewFor = fields.overviewFor;
		this.slugUrl = fields.slugUrl;
		this.system = new System(system);
		this.title = fields.title;
	}
}
