import UmbracoImage from './umbracoImage';

export default class Logo {
    alt?: string;
    caption?: string;
    image: UmbracoImage;
    slogan: string;

    constructor(data) {
        this.alt = data.alt || 'logo';
        this.caption = data.caption;
        this.image = new UmbracoImage(data.siteLogo || {});
        this.slogan = data.logoSlogan;
    }
}
