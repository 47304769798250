import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './PriceCard.scss';
import { Card, Text, Button, Label } from '~source/ui/components';
import InitContext from '~source/context/init';
import joinClassNames from '~source/utils/join-class-names';

interface Props {
    //TODO In the future it wil receive an object from the endpoint as prop which needs to be typed
    badge?: string;
    eventId?: string;
    travelInfo?: boolean;
    pricingInfo?: boolean;
    border?: boolean;
    children: number;
    customStyle?: any;
}

const PriceCard: React.FunctionComponent<Props> = ({
    badge,
    children,
    eventId,
    travelInfo = true,
    pricingInfo = true,
    border = true,
    customStyle,
}) => {
    const { t } = useTranslation();
    const { checkoutUrl } = React.useContext(InitContext);

    const renderLabel = () => {
        return badge === '' ? null : (
            <div className={styles.label}>
                <Label type="blue" className={styles.priceTagLabel}>
                    {badge}
                </Label>
            </div>
        );
    };

    const renderRoundedPrice = () => {
        const price = Math.ceil(children);
        return `${price},-`;
    };

    const onAssembleClick = () => {
        window.location.href = `${checkoutUrl}/ticket/${eventId}`;
    };

    return (
        <div className={styles.priceCard} data-testid="price-card">
            {pricingInfo && renderLabel()}
            <Card
                customStyles={joinClassNames(
                    border ? styles.priceCardBorder : '',
                    customStyle ? customStyle : '',
                )}
            >
                <Text customStyle={styles.text}> {t('info.priceInfo')}</Text>
                <div className={styles.price}>{renderRoundedPrice()}</div>
                {/* {travelInfo && (
					<Text customStyle={styles.text} textStyle={styles.travelInfoIcon}>
						{t('info.travelInfo')}
					</Text>
				)} */}
                {eventId && (
                    <Button clickHandler={onAssembleClick}>
                        {t('buttons.assemble')}
                    </Button>
                )}
                {/* <Link to={'/'}>
					<Text customStyle={styles.text} textStyle={styles.linkIcon}>
						Direct Boeken
					</Text>
				</Link> */}
            </Card>
        </div>
    );
};

export default PriceCard;
