import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Tag from '../Tag/Tag';
import styles from './ContactWidget.scss';
import { ContactOptionProp } from '~source/types/contact';
import ContactLink from '../ContactLink/ContactLink';
import { timeStringToDate } from '~source/utils/time-string-to-date';
import { isTimeWithinTimeFrame } from '~source/utils/time-is-within-time-frame';
import InitContext from '~source/context/init';
import useCdn from '~source/hooks/useCdn';

interface Props {
	contactOptions: ContactOptionProp[];
	startTimeCustomerService?: string; // eg. 9:00
	endTimeCustomerService?: string; // eg. 17:00
}

const PROFILE_PICTURE_SIZE = 85;

const ContactWidget: React.FunctionComponent<Props> = ({
	contactOptions,
	startTimeCustomerService = '09:00',
	endTimeCustomerService = '17:00',
}) => {
	const { t } = useTranslation();
	const { requestCdn } = useCdn();

	const { contactInfo } = React.useContext(InitContext);

	const startTime = timeStringToDate(startTimeCustomerService);
	const endTime = timeStringToDate(endTimeCustomerService);
	const isCustomerServiceAvailable = isTimeWithinTimeFrame({
		startTime,
		endTime,
	});

	const renderContactAvatar = React.useCallback((contactInfo) => {
		return contactInfo ? (
			<img
				src={requestCdn(contactInfo.contactAvatar.file.src)}
				width={PROFILE_PICTURE_SIZE}
				height={PROFILE_PICTURE_SIZE}
			/>
		) : null;
	}, []);

	return (
		<div className={styles.root}>
			<p className={styles.helpText}>{t('contact.helpText')}</p>
			<div className={styles.contactProfile}>
				<div className={styles.profilePicture}>
					{renderContactAvatar(contactInfo)}
				</div>
				<div className={styles.contactInformation}>
					<div className={styles.availability}>
						{!isCustomerServiceAvailable && (
							<span className={styles.timeFrame}>
								{t('contact.availability.timeFrame', {
									startTime: startTimeCustomerService,
									endTime: endTimeCustomerService,
								})}
							</span>
						)}
						{isCustomerServiceAvailable && (
							<Tag
								label={t('contact.availability.isAvailable')}
								fontSize={14}
								className={styles.availabilityIcon}
								color="secondary"
							/>
						)}
					</div>
					{contactOptions &&
						contactOptions.length > 0 &&
						contactOptions.map(({fields}) => {
							return (
								<ContactLink
									key={fields.value}
									type={fields.type}
									to={fields.value}
								/>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default ContactWidget;
