import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            ['en-GB']: {
                translation: {
                    badge: {
                        confirmedLeft: 'CONFIRMED',
                        confirmedRight: 'Match Date confirmed',
                        unconfirmedLeft: 'NOT CONFIRMED',
                        confirmedInfo:
                            'More information regarding the confirmed status of this match.',
                        unconfirmedInfo:
                            'This match is unconfirmed and here you can read more information about that.',
                        ourTip: 'Our Tip',
                    },
                    buttons: {
                        assemble: 'QUICK VIEW',
                    },
                    contact: {
                        title: 'Contact',
                        helpText:
                            'Do not hesitate to contact us via phone, WhatsApp or mail. We are standing by!',
                        availability: {
                            isAvailable: 'Available',
                            isUnavailable: 'Unavailable',
                            timeFrame:
                                'Available between {{startTime}} and {{endTime}}',
                        },
                    },
                    filter: {
                        calendar: {
                            from: 'From',
                            till: 'Till',
                        },
                        competitionFilter: 'SORT BY COMPETITION',
                        dateFilter: 'SORT BY DATE',
                        oponentFilter: 'SORT BY OPONENT',
                        priceFilter: 'SORT BY PRICE',
                        travelTimeFilter: 'SORT BY DAYS',
                    },
                    info: {
                        airport: 'Airport',
                        moreInfo: 'More Info',
                        priceInfo: 'from',
                        travelInfo: 'Includes flight, hotel and tickets',
                    },
                    labels: {
                        ticketsLeft: '8 tickets left!',
                        mostBooked: 'Most Booked',
                    },
                    select: {
                        travelTime: {
                            shortLong: 'Travel time short - long',
                            longShort: 'Travel time short - long',
                        },
                        date: {
                            shortLong: 'Datum van kort naar lang',
                            longShort: 'Datum van lang naar kort',
                        },
                        priceSelect: {
                            shortLong: 'Prijs van kort naar lang',
                            longShort: 'Prijs van lang naar kort',
                        },
                        days: {
                            optionOne: '2 days',
                            optionTwo: '3 days',
                            optionThree: '4 days',
                        },
                        price: {
                            lowToHigh: 'Low to High',
                            highToLow: 'High to Low',
                        },
                    },
                    title: {
                        blog: 'Blog Page',
                        clubPage: 'Club Page',
                        homePage: 'Home Page',
                        matchPage: 'Match Page',
                        matchTipTitle: 'Our Recommendation',
                        matchTipSubTitle: 'Selected for you',
                    },
                    usps: {
                        uspSeating: 'Seats next to each other',
                        uspMatchProtection: 'Match protection',
                        uspPricing: 'Best prices',
                        warranty: 'Our warranty:',
                        fansTravel: '7000 fans have been traveling',
                        experience: '15 years of experience',
                        socialProof: '8.9 graded',
                    },
                    club: {
                        clubPhotos: 'Photos',
                        clubSpecialties: 'Specialties',
                        clubInfoLink: 'Everything about',
                        clubTravelTips: 'Travel Tips',
                        versus: 'vs',
                    },
                    pagination: {
                        prev: 'Prev',
                        next: 'Next',
                    },
                    header: {
                        subtitle: 'Football Trips',
                        slogan: 'Tickets, hotels & flight!',
                        searchPlaceholder: 'Search for Match...',
                        clubPageSearchButton: 'Search Match',
                        homePageSearchButton: 'Find your Match',
                        homePageBottom: {
                            including: 'including:',
                            flight: 'flight',
                            hotel: 'hotel',
                            matchTicket: 'match ticket',
                        },
                        homePageBadge: 'Official partner Camp Nou',
                    },
                    search: {
                        placeholderDefault: 'Club, competition or match...',
                        placeholderMatch: 'Search for a match...',
                        buttonLabel: 'Search trip',
                        noResultsText:
                            'Too bad, we could not find any results.',
                    },
                    support: {
                        questions: 'Do you have questions?',
                        questionsContent:
                            'Regarding your ticket, flight, hotels, or something else?',
                        questionsFooter: "Call me. I'll gladly assist you.",
                        faq: "Checkout our FAQ's",
                        trustPilotStars: 'Trustpilot Stars',
                        trustPilotLogo: 'Trustpilot Logo',
                        trustPilotFooter: 'We strive for a 9',
                        trustPilotTitle:
                            'Convince yourself with the stories from our clients',
                        trustPilotSubtitle:
                            'More than 7000 fans went before you',
                    },
                    matchCard: {
                        teamImage: 'Team Image',
                        hotel: 'Hotel',
                        flight: 'Flight',
                        ticket: 'Tickets',
                        mostPurchased: 'Most Purchased',
                        moreInfo: 'More info',
                    },
                    newsletter: {
                        yourEmail: 'Your email adres',
                        apply: 'Apply',
                        footNote:
                            "You'll receive 1x a month a newsletter from us with interesting footbal trips and sales.",
                        privacy: 'Read our cookie- and privacy-policy.',
                    },
                    blog: {
                        lastEdited: 'Last Edited',
                        averageReadingTime: 'Average reading time',
                    },
                    sidebar: {
                        whyEquipo: 'Why Equipo',
                        stayUpToDate: 'Stay up to date',
                        newsletterTagline:
                            'Receive the latest deals and promotions directly in your inbox',
                    },
                    footer: {
                        copyright:
                            'Eurotravel Sports Netherlands BV. All rights resereved.',
                        subtitle: 'We will gladly assist you further',
                    },
                },
            },
            ['nl-NL']: {
                translation: {
                    badge: {
                        confirmedLeft: 'DEFINITIEF',
                        confirmedRight: 'Speeldatum bevestigd',
                        unconfirmedLeft: 'NIET DEFINITIEF',
                        confirmedInfo:
                            'Dit wedstrijd is definitief en hier kunt u meer informatie vinden erover.',
                        unconfirmedInfo:
                            'Dit wedstrijd is juist NIET definitief! Kijk uit.',
                        ourTip: 'Onze tip',
                    },
                    buttons: {
                        assemble: 'SNEL BEKIJKEN',
                    },
                    contact: {
                        title: 'Contact',
                        helpText:
                            'Vragen of advies nodig? Bel, WhatsApp, of mail ons.',
                        availability: {
                            isAvailable: 'Beschikbaar',
                            isUnavailable: 'Onbeschikbaar',
                            timeFrame:
                                'Beschikbaar tussen {{startTime}} tot {{endTime}}',
                        },
                    },
                    filter: {
                        calendar: {
                            from: 'Van',
                            till: 'Tot',
                        },
                        competitionFilter: 'FILTER OP COMPETITIE',
                        dateFilter: 'FILTER OP DATUM',
                        oponentFilter: 'FILTER OP TEGENSTANDER',
                        priceFilter: 'SORTEER OP PRIJS',
                        travelTimeFilter: 'SORTEER OP REISDUUR',
                    },
                    info: {
                        airport: 'Vliegveld',
                        moreInfo: 'Meer info',
                        priceInfo: 'vanaf',
                        travelInfo: 'Incl. vlucht, hotel en tickets',
                    },
                    labels: {
                        mostBooked: 'Meest geboekt',
                        ticketsLeft: 'Nog 8 tickets!',
                    },
                    select: {
                        travelTime: {
                            shortLong: 'Duur van kort naar lang',
                            longShort: 'Duur van lang naar kort',
                        },
                        date: {
                            shortLong: 'Datum van kort naar lang',
                            longShort: 'Datum van lang naar kort',
                        },
                        priceSelect: {
                            shortLong: 'Prijs van kort naar lang',
                            longShort: 'Prijs van lang naar kort',
                        },
                        days: {
                            optionOne: '2 dagen',
                            optionTwo: '3 dagen',
                            optionThree: '4 dagen',
                        },
                        price: {
                            lowToHigh: 'Laag naar Hoog',
                            highToLow: 'Hoog naar laag',
                        },
                    },
                    title: {
                        blog: 'Blog Pagina',
                        clubPage: 'Match Pagina',
                        homePage: 'Home Pagina',
                        matchPage: 'Match Page',
                        matchTipTitle: 'Onze aanraders',
                        matchTipSubTitle: 'Speciaal voor jou geselecteerd',
                    },
                    usps: {
                        uspSeating: 'Zitplaatsen bij elkaar',
                        uspMatchProtection: 'Wedstrijdbescherming',
                        uspPricing: 'Beste prijs',
                        warranty: 'Onze garantie:',
                        fansTravel: 'Al 7000 fans zijn met ons op reis gegaan',
                        experience: 'Meer dan 15 jaar ervaring',
                        socialProof: 'Beoordeeld met een 8.9',
                    },
                    club: {
                        clubPhotos: "Foto's",
                        clubSpecialties: 'Bijzonderheden',
                        clubInfoLink: 'Alles over',
                        clubTravelTips: 'Reistips',
                        versus: 'vs',
                    },
                    pagination: {
                        prev: 'Vorige',
                        next: 'Volgende',
                    },
                    header: {
                        subtitle: 'Football Trips',
                        slogan: 'Tickets, hotels & flight!',
                        searchPlaceholder: 'Zoek naar wedstrijd...',
                        clubPageSearchButton: 'ZOek wedstrijd',
                        homePageSearchButton: 'Vind je reis',
                        homePageBottom: {
                            including: 'inclusief:',
                            flight: 'vlucht',
                            hotel: 'hotel',
                            matchTicket: 'wedstrijd ticket',
                        },
                        homePageBadge: 'Official partner Camp Nou',
                    },
                    search: {
                        placeholderDefault: 'Club, competitie of wedstrijd...',
                        placeholderMatch: 'Zoek naar een wedstrijd...',
                        buttonLabel: 'Zoek reis',
                        noResultsText:
                            'Helaas we hebben geen resultaten gevonden',
                        searchPlaceholder: 'Zoek naar een wedstrijd...',
                        clubPageSearchButton: 'Zoek Reis',
                        homePageSearchButton: 'Vind je Reis',
                        homePageBottom: {
                            including: 'inclusief:',
                            flight: 'vlucht',
                            hotel: 'hotel',
                            matchTicket: 'wedstrijdticket',
                        },
                        homePageBadge: 'Official partner Camp Nou',
                        homePageTitle: 'Niets is mooier dan een vol stadion',
                        homePageSubTitle:
                            'Boek gemakkelijk je reis. Wij regelen de rest.',
                    },
                    support: {
                        questions: 'Heb je vragen?',
                        questionsContent:
                            'Over je ticket, hotel, vlucht of iets anders?',
                        questionsFooter: 'Bel mij gerust. Ik help je graag.',
                        faq: "Bekijk de FAQ's",
                        trustPilotStars: 'Trustpilot Stars',
                        trustPilotLogo: 'Trustpilot Logo',
                        trustPilotFooter: 'Wij werken hard voor een 9',
                        trustPilotTitle:
                            'Overtuid jezelf met de verhalen van onze klanten',
                        trustPilotSubtitle:
                            'Al meer dan 7000 fans gingen voor je',
                    },
                    matchCard: {
                        teamImage: 'Team photo',
                        hotel: 'Hotel',
                        flight: 'Vlucht',
                        ticket: 'Tickets',
                        mostPurchased: 'Meest verkocht',
                        moreInfo: 'Meer info',
                    },
                    newsletter: {
                        yourEmail: 'Je e-mailadres',
                        apply: 'Inschrijven',
                        footNote:
                            'Je ontvang 1x per maand van ons een nieuwsbrief met daarin aantrekkelijke voetbalreizen en acties.',
                        privacy: 'Read our cookie- and privacy-policy.',
                    },
                    blog: {
                        lastEdited: 'Laatst bewerkt',
                        averageReadingTime: 'Gemiddelde leestijd',
                    },
                    sidebar: {
                        whyEquipo: 'Waarom Equipo',
                        stayUpToDate: 'Blijf op te hoogte',
                        newsletterTagline:
                            'Ontvang de laatste aanbiedingen en acties direct in je mailbox',
                    },
                    footer: {
                        copyright:
                            'Eurotravel Sports Netherlands BV. Alle rechten voorbehouden.',
                        subtitle: 'Wij helpen je graag verder',
                    },
                    eventInfoTitle: 'Inhoud standaard pakket',
                    eventDetails: 'Arrangement',
                    transport: 'Vervoer',
                    tickets: 'Tickets',
                    hotel: 'Hotel',
                    matchInfo: 'Wedstrijdinformatie',
                    competition: 'Competition',
                    matchDate: 'Wedstrijddatum',
                    travelDate: 'Reisdata',
                    departureDate: 'Heenreis',
                    destinationDate: 'Terugreis',
                    stay: 'Lengte verblijf',
                    depAirport: 'Vertrek luchthaven',
                    arrAirport: 'Aankomst luchthaven',

                    price: 'Prijs',
                    footNote:
                        '* In het boekingsproces kun je wijzigingen aan dit standaard arrangement doorvoeren. De huidige opties worden jou aangeboden op basis van de actuele beschikbaarheid en goedkoopste prijzen.',
                    ratingText: 'We zijn trots op onze',
                    ratingTextEnd: 'op naar een',
                    stayUpToDate: 'Stay up-to-date',
                    stayUpToDateText:
                        'Ontvang de laatste aanbiedingen en acties direct in je mailbox',
                    whyEquipo: 'Waarom Equipo',
                    signUp: 'Inschrijven',
                    emailAddressPlaceholder: 'Je emailadres...',
                },
            },
        },
        lng: window.ETCP_SITE_CONFIG.locale,
        fallbackLng: window.ETCP_SITE_CONFIG.locale,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
