import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './EventCard.scss';
import { Card, Text, PriceCard, Label } from '~source/ui/components';
import ReactCountryFlag from 'react-country-flag';
import ReactTooltip from 'react-tooltip';
import joinClassNames from '../../../utils/join-class-names';
import Shirt from '../Shirt/Shirt';
import Tag from '../Tag/Tag';
import { EventLevel1 } from '~source/core/models/event';

interface Props {
    customStyle?: string;
    event: EventLevel1;
    priceLabel: string;
}

const EventCard: React.FunctionComponent<Props> = ({
    customStyle,
    event,
    priceLabel,
}) => {
    const { t } = useTranslation();
    const {
        basePrice,
        dateStart,
        id,
        isConfirmed,
        serie,
        teamAway,
        teamHome,
        totalTickets,
        venue,
    } = event;

    const renderLabel = () => {
        return totalTickets ? (
            <div className={styles.ticketLabel} data-testid="match-card-label">
                <Label type="green" className={styles.ticketsIcon}>
                    {t('labels.ticketsLeft')}
                </Label>
            </div>
        ) : null;
    };

    const renderBadge = () => {
        if (isConfirmed) {
            return (
                <div
                    className={styles.badgesWrapper}
                    data-testid="match-card-confirmed"
                >
                    <div
                        className={styles.badgeLeft}
                        data-tip={t('badge.confirmedInfo')}
                        data-effect="solid"
                    >
                        <Tag
                            label={t('badge.confirmedLeft')}
                            color="grey"
                            fontSize={12}
                            className={styles.confirmationCheckIcon}
                        />
                    </div>
                    <Text customStyle={styles.badgeRight}>
                        {t('badge.confirmedRight')}
                    </Text>
                </div>
            );
        } else {
            return (
                <div
                    className={styles.badgesWrapper}
                    data-testid="match-card-confirmed"
                >
                    <div
                        className={styles.badgeLeft}
                        data-tip={t('badge.unconfirmedInfo')}
                        data-effect="solid"
                    >
                        <Tag
                            label={t('badge.unconfirmedLeft')}
                            color="grey"
                            fontSize={12}
                        />
                    </div>
                </div>
            );
        }
    };

    // TODO type the team arg when API is done
    const renderTeam = team => {
        return (
            <div className={styles.team} data-testid="match-card-shirt">
                <Shirt color={team.color} />
                <span className={styles.teamName}>{team.name}</span>
            </div>
        );
    };

    return (
        <div className={styles.matchcard} data-testid="match-card">
            <ReactTooltip place="right" effect="solid" />
            <Card customStyles={styles.cardWidth}>
                <div className={styles.wrapper}>
                    <div className={`${styles.colLeft} ${customStyle}`}>
                        <div className={styles.teams}>
                            {renderTeam(teamHome)}
                            <div className={styles.versus}>
                                {t('club.versus')}
                            </div>
                            {renderTeam(teamAway)}
                        </div>
                        {renderBadge()}
                        <div
                            className={styles.labelWrapper}
                            data-testid="match-card-label"
                        >
                            {renderLabel()}
                            <hr />
                        </div>
                        <div className={styles.wrapper}>
                            <div>
                                <Text iconSpacing>{serie.name}</Text>
                                <Text
                                    iconSpacing
                                    textStyle={styles.departureDate}
                                >
                                    {dateStart}
                                </Text>
                                {/* <Link
                                    className={joinClassNames(
                                        styles.link,
                                        styles.linkDesktop,
                                    )}
                                    to="/"
                                >
                                    {t('info.moreInfo')}
                                </Link> */}
                            </div>
                            <div>
                                {venue && (
                                    <Text>
                                        {venue.countryCode && (
                                            <>
                                                <ReactCountryFlag
                                                    code={venue.countryCode}
                                                    svg
                                                />{' '}
                                            </>
                                        )}
                                        {`${venue.name}, ${venue.city}`}
                                    </Text>
                                )}
                                {/* <Text textStyle={styles.departureIcon}>
                                    Heen 30 nov
                                </Text>
                                <Text textStyle={styles.arrivalIcon}>
                                    Terug 5 dec
                                </Text>
                                <Text customStyle={styles.airport}>
                                    {t('info.airport')}: {departureAirport}
                                </Text>
                                <Link
                                    to="/"
                                    className={joinClassNames(
                                        styles.link,
                                        styles.linkMob,
                                    )}
                                >
                                    {t('info.moreInfo')}
                                </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.colRight}>
                        <PriceCard badge={priceLabel} eventId={id}>
                            {Number(basePrice)}
                        </PriceCard>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default EventCard;
