import { useEffect, useState } from 'react';

export function useKeyPress( targetKey ){
    const [ keyPressed, setKeyPressed ] = useState(false)

    function downHandler({ keyCode }) {
        if(keyCode === targetKey){
            setKeyPressed(true)
        }
    }

    function upHandler({ keyCode }){
        if(keyCode === targetKey){
            setKeyPressed(false)
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', upHandler);
        window.addEventListener('keydown', downHandler);

        return () => {
            window.removeEventListener('keyup', upHandler);
            window.removeEventListener('keydown', downHandler);
        }
    }, [])

    return keyPressed
}