import UmbracoImage from './umbracoImage';

export default class ContactInfo {
	availabilityEndTime?: string;
	availabilityStartTime?: string;
	contactAvatar?: UmbracoImage;
	contactText?: string;
	contactOptions: Array<any>;

	constructor(data) {
		this.availabilityEndTime = data.availabilityEndTime;
		this.availabilityStartTime = data.availabilityStartTime;
		this.contactAvatar = new UmbracoImage(data.contactAvatar || {});
		this.contactText = data.contactText;
		this.contactOptions = data.contactOptions;
	}
}
