import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppLogo, ContactLink } from '../index';
import styles from './HeaderTopRow.scss';
import joinClassNames from '../../../utils/join-class-names';
import InitContext from '~source/context/init';
import useCdn from '~source/hooks/useCdn';

const renderContactLink = info => {
    return !info
        ? null
        : info.contactOptions &&
              info.contactOptions.length > 0 &&
              info.contactOptions.map(({ fields }) => {
                  return (
                      <ContactLink
                          key={fields.value}
                          type={fields.type}
                          to={fields.value}
                          position="below"
                      />
                  );
              });
};

const HeaderTopRow: React.FunctionComponent = () => {
    const { logo, contactInfo, headerIcons } = React.useContext(InitContext);
    const { requestCdn } = useCdn();
    const iconSize = 40;

    const renderSlogan = () => {
        return !logo ? null : (
            <div className={joinClassNames(styles.group, styles.logo)}>
                <Link to="/">
                    <AppLogo logoUrl={requestCdn(logo.image.file.src)} />
                </Link>
                <div
                    className={joinClassNames(
                        styles.slogan,
                        styles.hideOnSmallScreen,
                    )}
                >
                    {logo.slogan}
                </div>
            </div>
        );
    };

    const renderIcons = () => {
        return !headerIcons
            ? null
            : headerIcons.map(icon => {
                  return (
                      <img
                          alt={icon.name}
                          key={icon.file.src}
                          src={requestCdn(icon.file.src)}
                          width={iconSize}
                      />
                  );
              });
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                {renderSlogan()}
                <div
                    className={joinClassNames(
                        styles.group,
                        styles.hideOnSmallScreen,
                    )}
                >
                    {renderIcons()}
                </div>
                <div>{renderContactLink(contactInfo)}</div>
                <div
                    className={joinClassNames(
                        styles.group,
                        styles.visibleOnSmallScreen,
                    )}
                />
            </div>
        </div>
    );
};

export default HeaderTopRow;
