import * as React from 'react';
import styles from './Button.scss';

interface Props {
    children: string;
    clickHandler: () => void;
    customStyle?: string;
}

const Button: React.FunctionComponent<Props> = ({ children, clickHandler, customStyle }) => {
    return (
        <button
            className={`${styles.button} ${customStyle}`}
            data-testid="ui-button"
            onClick={clickHandler}
        >
            {children.toUpperCase()}
        </button>
    );
};

export default Button;
