import * as React from 'react';
import styles from './Label.scss';
import joinClassNames from '../../../utils/join-class-names';

interface Props {
    type: string;
    children: React.ReactNode;
    className?: string;
}

const Label: React.FunctionComponent<Props> = ({ type, children, className }) => {
    const renderLabel = type => {
        if(type === 'green') {
            return <div className={joinClassNames(styles.label, className)}>{children}</div>;
        } else if(type === 'blue') {
            return <div className={joinClassNames(styles.label, styles.blue, className)}>{children}</div>
        } else {
            return <div className={joinClassNames(styles.label, styles.orange, className)}>{children}</div>
        }
    };
    return renderLabel(type);
};

export default Label;
