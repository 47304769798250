export default class Seo {
    description: string;
    keywords: string[];
    title: string;

    constructor(data: any = {}) {
        this.description = data.description || data.metaDescription;
        this.keywords = data.keywords || data.metaKeywords;
        this.title = data.title || data.metaTitle;
    }
}
