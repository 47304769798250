import System from './system';

class Serie {
    id: string;
    name: string;
    system: System;
    text: string;

    constructor(props) {
        const { fields, system } = props;
        this.id = fields && fields.systemId;
        this.name = fields && fields.serieName;
        this.system = new System(system || {});
        this.text = fields && fields.enrichment_text;
    }
}

export default Serie;
