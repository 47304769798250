import * as React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../routes';
import styles from './Breadcrumbs.scss';

interface Props {
	route: string;
}

const renderBreadcrumbItems = (items: Array<string>) => {
	return items.map((item: string) => {
		return paths[item] ? (
			<Link
				data-testid="breadcrumb-item"
				key={item}
				className={styles.crumbArrow}
				to={paths[item]}
			>
				{item}
			</Link>
		) : null;
	});
};

const Breadcrumbs: React.FunctionComponent<Props> = ({ route = '/' }) => {
	const routeItems = route.split('/');

	// the shift methode will remove the first empty string from the array
	routeItems.shift();

	return (
		<div className={styles.breadcrumbs} data-testid="breadcrumb">
			{renderBreadcrumbItems(routeItems)}
		</div>
	);
};

export default Breadcrumbs;
