import * as React from 'react';

import { Container, Html } from '~source/ui/components';
import { ModuleFieldsRichText } from '~source/core/interfaces';
import s from './RichTextModule.scss';

interface Props {
    fields: ModuleFieldsRichText;
}

const RichTextModule: React.FunctionComponent<Props> = ({ fields }) => {
    return (
        <Container>
            <Html className={s.root}>{fields.text}</Html>
        </Container>
    );
};

export default RichTextModule;
