import Team from './team';
import Serie from './serie';
import Venue from './venue';
import System from './system';
import EventTaxonomyPage from './eventTaxonomyPage';

export class Event {
    basePrice: string;
    dateEnd: string;
    dateStart: string;
    dateBookingEnd: string;
    dateBookingStart: string;
    dateMinimumEnd: string;
    dateMinimumStart: string;
    id: string;
    isConfirmed: boolean;
    name: string;
    system: System;
    totalTickets?: number;

    constructor(props) {
        const { fields, system } = props;

        this.basePrice = fields && fields.basePrice;
        this.dateEnd = fields && fields.endDateAndTime;
        this.dateStart = fields && fields.dateAndTime;
        this.dateBookingEnd = fields && fields.bookingStopAt;
        this.dateBookingStart = fields && fields.bookingStartAt;
        this.dateMinimumEnd = fields && fields.dateMinimumEndAt;
        this.dateMinimumStart = fields && fields.dateMinimumStartAt;
        this.id = fields && fields.systemId;
        this.isConfirmed = fields && fields.confirmed;
        this.name =
            fields && fields.eventName && fields.eventName.replace('vs', '-');
        this.system = new System(system || {});
        // TODO: Connect total tickets prop when available from API
        this.totalTickets = fields && fields.totalTickets;
    }
}

export class EventLevel0 extends Event {
    serie: EventTaxonomyPage;
    teamAway: EventTaxonomyPage;
    teamHome: EventTaxonomyPage;
    venue: EventTaxonomyPage;

    constructor(props) {
        super(props);
        const { fields } = props;

        this.serie = new EventTaxonomyPage(fields.serie || {});
        this.teamAway = new EventTaxonomyPage(fields.awayTeam || {});
        this.teamHome = new EventTaxonomyPage(fields.homeTeam || {});
        this.venue = new EventTaxonomyPage(fields.venue || {});
    }
}

export class EventLevel1 extends Event {
    serie: Serie;
    teamAway: Team;
    teamHome: Team;
    venue: Venue;

    constructor(props) {
        super(props);
        const { fields } = props;

        this.serie = new Serie(fields.serie || {});
        this.teamAway = new Team(fields.awayTeam || {});
        this.teamHome = new Team(fields.homeTeam || {});
        this.venue = new Venue(fields.venue || {});
    }
}
