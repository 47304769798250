import * as React from 'react';
import styles from './PriceTag.scss';
import { Text } from '../../components';
import { useTranslation } from 'react-i18next';
import joinClassNames from '../../../utils/join-class-names';

interface Props {
    price?: number;
    withText?: boolean;
    hasInternalText?: boolean;
}

const PriceTag: React.FunctionComponent<Props> = ({
    price,
    withText = false,
    hasInternalText = false,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {withText && (
                <Text customStyle={styles.text}> {t('info.priceInfo')}</Text>
            )}
            {hasInternalText && (
                <Text customStyle={styles.textInternal}>
                    {' '}
                    {t('info.priceInfo')}
                </Text>
            )}
            <div
                className={joinClassNames(
                    styles.priceTag,
                    hasInternalText ? styles.priceTagWithText : '',
                )}
            >
                {price},-
            </div>
        </>
    );
};

export default PriceTag;
