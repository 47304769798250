class EventTaxonomyPage {
    id: string;
    slug: string;
    title: string;

    constructor(props) {
        this.id = props.id;
        this.slug = props.id;
        this.title = props.id;
    }
}

export default EventTaxonomyPage;
