import * as React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import Page from '~source/core/models/page';
import Seo from '~source/core/models/seo';
import useApi from '~source/hooks/useApi';
import ModuleResolver from './ModuleResolver';
import useEndpoint from '~source/hooks/useEndpoint';
import { LoadScreen, Events } from '~source/ui/components';

function renderFavicon() {
    return (
        <link
            rel="shortcut icon"
            href={`${window.ETCP_PIPELINE_CONFIG.cdnUrl}assets/images/favicon.png`}
        />
    );
}

function renderPageTitle(seo: Seo) {
    if (!seo.title) {
        return <title>{window.ETCP_SITE_CONFIG.siteName}</title>;
    }

    return (
        <title>
            {seo.title} - {window.ETCP_SITE_CONFIG.siteName}
        </title>
    );
}

function renderSeoMeta(seo: Seo) {
    return Object.entries(seo).map(entry => {
        const [name, content] = entry;
        return (
            <meta
                key={name}
                name={name}
                content={
                    (Array.isArray(content) && content.join(',')) || content
                }
            />
        );
    });
}

function toSlugRequest(location) {
    return (location.pathname === '/' && '/matches') || location.pathname;
}

const regexIndexHtml = new RegExp('index.html');

function isIndexHtml(str) {
    return regexIndexHtml.test(str);
}

const PageResolver = withRouter(({ location }) => {
    const { createCmsEndpoint } = useEndpoint();
    const [pagesRequest, setPagesRequest] = React.useState(
        createCmsEndpoint(`pages${toSlugRequest(location)}`),
    );
    const [pageResponse, setEndpoint] = useApi(pagesRequest, 'GET');
    const [pageData, setPageData] = React.useState(new Page({}));
    const locationRef = React.useRef(location.pathname);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        setEndpoint(pagesRequest);
        setIsLoading(true);
    }, [pagesRequest]);

    React.useEffect(() => {
        if (pageResponse) {
            setPageData(new Page(pageResponse));
        }
        setIsLoading(false);
    }, [pageResponse]);

    React.useEffect(() => {
        // Location changed?
        if (locationRef.current !== location.pathname) {
            const newPagesRequest = createCmsEndpoint(
                `pages${toSlugRequest(location)}`,
            );
            // Pages request created based on location changed relative to current pagesRequest
            if (pagesRequest !== newPagesRequest) {
                setPagesRequest(newPagesRequest);
            }
            locationRef.current = location.pathname;
        }
    }, [location]);

    if (isLoading) return <LoadScreen isAbsolute={false} />;

    if (isIndexHtml(location.pathname)) return <Redirect to="/" />;

    return (
        <>
            <Helmet>
                {renderPageTitle(pageData.seo)}
                {renderSeoMeta(pageData.seo)}
                {renderFavicon()}
            </Helmet>
            {pageData.overviewFor === 'Events' && <Events />}
            {!pageData.overviewFor && (
                <ModuleResolver modules={pageData.modules} />
            )}
        </>
    );
});

export default PageResolver;
