import * as React from 'react';
import styles from './Select.scss';

interface Props {
	options: Array<string>;
	customStyles?: string;
}

const Select: React.FunctionComponent<Props> = ({ options, customStyles }) => {
	return (
		<select className={`${styles.select} ${customStyles}`}>
			{options.map((option: string) => {
				return (
					<option key={option} value={option}>
						{option}
					</option>
				);
			})}
		</select>
	);
};

export default Select;
