import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.scss';
import joinClassNames from '../../../utils/join-class-names';
import InitContext from '~source/context/init';

interface Props {
	navbarIsOpen: boolean;
	setNavbar: (arg1: boolean) => void;
}
interface menuProps {
	label: string;
	menu?: {
		label: string;
		url?: string;
		submenu?: {
			label: string;
			url: string;
		}[];
	}[];
	url?: string;
}

interface subMenuProps {
	label: string;
	url?: string;
	submenu: {
		label: string;
		url: string;
	}[];
}

const Navbar: React.FunctionComponent<Props> = ({
	navbarIsOpen,
	setNavbar,
}) => {
	const closeNav = () => setNavbar(false);
	const { navigation } = React.useContext(InitContext);

	const renderLinks = () => {
		return !navigation ? null
			: navigation.headerNavigation.map((nav, i) => {
					return (
						<Link
							to={nav.slug}
							className={styles.navbarItem}
							onClick={closeNav}
							key={`navbarItem-${i}`}
						>
							<li className={styles.navbarItemContent}>
								{nav.title}
							</li>
						</Link>
					);
				});
	};

	return (
		<div
			className={joinClassNames(navbarIsOpen ? styles.mobileWrapper : '')}
		>
			<nav>
				<ul
					className={joinClassNames(
						styles.navbar,
						navbarIsOpen ? styles.mobile : '',
					)}
				>
					{renderLinks()}
				</ul>
			</nav>
		</div>
	);
};

export default Navbar;
