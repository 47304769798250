import * as React from 'react';
import Module from '~source/core/models/Module';
import {
    RichTextModule,
    WarrantyModule,
    TrustpilotModule,
} from '~source/ui/modules';

interface Props {
    modules: Module[];
}

function renderModule(contentType, fields: any = {}, key) {
    switch (contentType) {
        case 'richTextModule':
            return <RichTextModule key={key} fields={fields} />;
        case 'warrantyModule':
            return <WarrantyModule key={key} fields={fields} />;
        case 'trustpilotModule':
            return <TrustpilotModule key={key} fields={fields} />;
        default:
            return (
                <React.Fragment key={key}>
                    Unknown module: {contentType}
                </React.Fragment>
            );
    }
}

const ModuleResolver: React.FunctionComponent<Props> = ({ modules }) => {
    if (!modules || modules.length < 1) return null;

    return (
        <>
            {modules.map(m => (
                <div key={m.system.id}>
                    {renderModule(m.system.contentType, m.fields, m.system.id)}
                </div>
            ))}
        </>
    );
};

export default ModuleResolver;
