import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Events.scss';
import {
    LoadScreen,
    EventCard,
    WidgetWrapper,
    ContactWidget,
    Pagination,
} from '~source/ui/components';
import { EventsLevel1 } from '~source/core/models/events';
import useApi from '~source/hooks/useApi';
import InitContext from '~source/context/init';
import scrollToTop from '~source/utils/scroll-to-top';
import useEndpoint from '~source/hooks/useEndpoint';

const Events: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { createPackagesEndpoint } = useEndpoint();
    const [currentPage, setCurrentPage] = React.useState(1);
    const currentPageRef = React.useRef(currentPage);
    const [eventsResponse, setEndpoint] = useApi(
        createPackagesEndpoint('events', { page: currentPage, level: 2 }),
        'GET',
    );
    const [matchesData, setMatchesData] = React.useState(new EventsLevel1({}));
    const [isFetchingEvents, setIsFetchingEvents] = React.useState(true);
    const { contactInfo } = React.useContext(InitContext);

    React.useEffect(() => {
        if (eventsResponse) {
            setIsFetchingEvents(false);
            setMatchesData(new EventsLevel1(eventsResponse));
        }
    }, [eventsResponse]);

    React.useEffect(() => {
        if (currentPage !== currentPageRef.current) {
            scrollToTop();
            setIsFetchingEvents(true);
            setEndpoint(
                createPackagesEndpoint('events', {
                    page: currentPage,
                    level: 2,
                }),
            );
            currentPageRef.current = currentPage;
        }
    }, [currentPage]);

    const renderEventCards = () => {
        return !matchesData ? (
            <div>...loading</div>
        ) : (
            matchesData.events.map((event: any) => {
                return (
                    <EventCard key={event.id} event={event} priceLabel={''} />
                );
            })
        );
    };

    return (
        <>
            <div className={styles.root}>
                <div className={styles.stickyWrapper}>
                    <WidgetWrapper
                        className={styles.widget}
                        title={t('contact.title')}
                    >
                        <ContactWidget
                            contactOptions={contactInfo.contactOptions}
                        />
                    </WidgetWrapper>
                </div>
                <div className={styles.matchCardWrapper}>
                    {renderEventCards()}
                </div>
            </div>
            {matchesData &&
                matchesData.events &&
                matchesData.events.length > 0 && (
                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={matchesData.events.length}
                        onChange={nextPage => {
                            setCurrentPage(nextPage);
                        }}
                        pageRangeDisplayed={11}
                        totalItemsCount={
                            matchesData.meta.totalPages *
                            matchesData.events.length
                        }
                    />
                )}
            {isFetchingEvents && <LoadScreen isFixed />}
        </>
    );
};

export default Events;
