import EventMeta from './eventMeta';
import { EventLevel0, EventLevel1 } from './event';

export class Events {
    events: Event[];
    meta: EventMeta;

    constructor(props: any = []) {
        const { data, meta } = props;

        this.events = (data && data.map(d => new EventLevel0(d))) || [];
        this.meta = new EventMeta(meta || {});
    }
}

export class EventsLevel1 extends Events {
    events: Event[];
    meta: EventMeta;

    constructor(props: any = []) {
        super(props);

        const { data, meta } = props;

        this.events = (data && data.map(d => new EventLevel1(d))) || [];
        this.meta = new EventMeta(meta || {});
    }
}
