import * as React from 'react';
import joinClassNames from '../../../utils/join-class-names';
import s from './ModuleTitle.scss';

interface Props {
    className?: string;
    children: React.ReactType | React.ComponentType | React.ReactNode;
}

const ModuleTitle: React.FunctionComponent<Props> = ({
    className,
    children,
}) => <h2 className={joinClassNames(s.root, className)}>{children}</h2>;

export default ModuleTitle;
