export default class System {
    contentType: string;
    createdAt?: string;
    editedAt?: string;
    id: string;
    locale?: string;
    name: string;
    revision?: number;
    type: string;
    slug?: string;

    constructor(data: any = {}) {
        this.contentType = data.contentType;
        this.createdAt = data.createdAt;
        this.editedAt = data.editedAt;
        this.id = data.id;
        this.locale = data.locale;
        this.name = data.name;
        this.revision = data.revision;
        this.type = data.type;
        this.slug = data.urlSegment;
    }
}
