import { isEmpty } from 'lodash';
import NavigationMenu from './navigationMenu';
import Footer from './footer';
import UmbracoImage from './umbracoImage';
import Logo from './logo';
import Usps from './usps';
import ContactInfo from './contactInfo';
import tryArray from '~source/utils/try-array';

export default class Init {
    cdnUrl: string;
    checkoutUrl: string;
    contactInfo: ContactInfo;
    footer: Footer;
    footerIcons: UmbracoImage[];
    headerIcons: UmbracoImage[];
    logo: Logo;
    navigation: NavigationMenu;
    usps: Usps;

    constructor(data) {
        if (isEmpty(data)) {
            return;
        }
        const { fields = {} } = data;

        this.cdnUrl = fields.cdnUrl;
        this.checkoutUrl = fields.checkoutUrl;
        this.contactInfo = new ContactInfo(fields);
        this.footer = new Footer(fields);
        this.footerIcons = tryArray(fields.footerIcons).map(
            i => new UmbracoImage(i),
        );
        this.headerIcons = tryArray(fields.headerIcons).map(
            i => new UmbracoImage(i),
        );
        this.logo = new Logo(fields);
        this.navigation = new NavigationMenu(tryArray(fields.headerNavigation));
        this.usps = new Usps(tryArray(fields.uSPs));
    }
}
