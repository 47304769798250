import System from './system';

class Venue {
    city: string;
    countryCode: string;
    id: string;
    latitude: string;
    longitude: string;
    name: string;
    system: System;
    text: string;
    timeZone: string;

    constructor(props) {
        const { fields, system } = props;
        this.city = fields && fields.city;
        this.countryCode = fields && fields.countryCode;
        this.id = fields && fields.systemId;
        this.latitude = fields && fields.latitude;
        this.longitude = fields && fields.longitude;
        this.name = fields && fields.venueName;
        this.system = new System(system || {});
        this.text = fields && fields.text;
        this.timeZone = fields && fields.timeZone;
    }
}

export default Venue;
