import System from './system';

class Team {
    abbreviation: string;
    color?: string;
    countryCode: string;
    id: string;
    name: string;
    nameShort: string;
    system: System;
    text?: string;

    constructor(props) {
        const { fields, system } = props;
        // TODO: Remove `abbrevation` when API corrects spelling
        this.abbreviation =
            (fields && fields.abbreviation) || (fields && fields.abbrevation);
        this.color = fields && fields.color;
        this.countryCode = fields && fields.countryCode;
        this.id = fields && fields.id;
        this.name = fields && fields.teamName;
        this.nameShort = fields && fields.shortName;
        this.system = new System(system || {});
        this.text = fields && fields.text;
    }
}

export default Team;
