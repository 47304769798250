import { isEmpty } from 'lodash';

interface File {
    crops: any;
    src: string;
}

export default class UmbracoImage {
    file: File;
    name: string;
    height: number;
    width: number;

    constructor(data) {
        if (isEmpty(data)) {
            return;
        }
        const { fields, system } = data;

        this.file = fields.umbracoFile;
        this.height = fields.umbracoHeight;
        this.name = system.name;
        this.width = fields.umbracoWidth;
    }
}
