import * as React from 'react';
import styles from './ToggleButton.scss';

interface Props {
    name: string;
    toggle: () => void;
}

const ToggleButton: React.FunctionComponent<Props> = ({ name, toggle }) => {
    return (
        <div className={styles.toggleButton}>
            <input
                className={styles.input}
                type="checkbox"
                name={name}
                onChange={toggle}
            />
            <label>{name}</label>
            <div className={styles.toggleBackground} />
            <div className={styles.toggleKnob} />
        </div>
    );
};

export default ToggleButton;
