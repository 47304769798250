import {
    ModuleFieldsRichText,
    ModuleFieldsTrustpilot,
    ModuleFieldsWarranty,
} from '~source/core/interfaces';
import System from './system';

export default class Module {
    // TODO: Replace `any` with all possible module types when BE finishes creating modules
    fields:
        | ModuleFieldsRichText
        | ModuleFieldsWarranty
        | ModuleFieldsTrustpilot
        | any;
    system: System;

    constructor(data: any = {}) {
        const { fields, system } = data;

        this.fields = fields;
        this.system = new System(system);
    }
}
