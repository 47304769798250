import { useEffect, useState } from 'react';
import { fetchUrl } from '~source/api';

export default function useApi(
    endpointFromProps: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
) {
    const [endpoint, setEndpoint] = useState(endpointFromProps);
    const [data, setData]: any = useState();
    useEffect(() => {
        async function fetchData() {
            const result = await fetchUrl({ endpoint, method });
            return setData(result);
        }
        fetchData();
    }, [endpoint]);

    return [data, setEndpoint];
}
