import * as React from 'react';
import joinClassNames from '~source/utils/join-class-names';
import s from './Container.scss';

interface Props {
    children: React.ReactType | React.ComponentType | React.ReactNode;
    className?: string;
    isTransparent?: boolean;
}

const Container: React.FunctionComponent<Props> = ({
    children,
    className,
    isTransparent = false,
}) => {
    return (
        <div
            className={joinClassNames(
                s.root,
                className,
                isTransparent && s.isTransparent,
            )}
            data-testid="ui-container"
        >
            <div className={s.container}>{children}</div>
        </div>
    );
};

export default Container;
