import * as React from 'react';
import { AppLogo } from '~source/ui/components';
import styles from './Footer.scss';
import { Link } from 'react-router-dom';
import InitContext from '~source/context/init';
import useCdn from '~source/hooks/useCdn';

const Footer: React.FunctionComponent = () => {
	const { footer, contactInfo, logo, footerIcons } = React.useContext(
		InitContext,
	);
	const { requestCdn } = useCdn();
	const LOGO_WIDTH = '100';
	const LOGO_HEIGHT = '38';

	const findContactOption = (option) => {
		const phone = option.fields.type === 'Phone';
		return phone;
	};

	const renderFooterColumns = React.useCallback((footer) => {
		return !footer
			? null
			: footer.footerNavigation.map((category) => {
					return (
						<div
							className={styles.footerColumn}
							key={category.fields.categoryTitle}
						>
							<p className={styles.footerTitle}>
								{category.fields.categoryTitle}
							</p>
							<ul>
								{category.fields.links.map((link) => {
									return (
										<li key={`footer-${link.name}`}>
											<Link to={link.url}>{link.name}</Link>
										</li>
									);
								})}
							</ul>
						</div>
					);
				});
	}, []);

	const renderLogo = () => {
		return !logo ? null : (
			<AppLogo
				logoUrl={requestCdn(logo.image.file.src)}
				width={LOGO_WIDTH}
				height={LOGO_HEIGHT}
			/>
		);
	};
	const renderFooterIcon = (icon) => {
		return (
			<div className={styles.footerIcon} key={icon.file.src}>
				<img src={requestCdn(icon.file.src)} alt={icon.name} />
			</div>
		);
	};
	return (
		<footer>
			<div className={styles.footerLinks}>
				{renderFooterColumns(footer)}
				<div className={styles.footerIcons}>
					{footerIcons && footerIcons.map(renderFooterIcon)}
				</div>
			</div>
			<div className={styles.footerInfo}>
				<div className={styles.footerCompany}>
					{renderLogo()}
					<p className={styles.footerCopyright}>
						{footer ? footer.copyrightText : null}
					</p>
				</div>
				<div className={styles.footerContact}>
					<a
						href={`tel:${contactInfo
							? contactInfo.contactOptions.find(findContactOption)
									.fields.value
							: null}`}
						className={styles.footerNumber}
					>
						{contactInfo ? (
							contactInfo.contactOptions.find(findContactOption)
								.fields.value
						) : null}
					</a>
					<p className={styles.footerSubtitle}>
						{contactInfo.contactText}
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
