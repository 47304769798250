const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style;

export default function scrollToTop() {
    if (supportsNativeSmoothScroll) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    } else {
        window.scrollTo(0, 0);
    }
}
