import * as React from 'react';
import styles from './Pagination.scss';
import joinClassNames from '~source/utils/join-class-names';
import { useTranslation } from 'react-i18next';

interface GetPaginationItemsProps {
    activePage: number;
    firstPage: number;
    lastPage: number;
    pageRange: number;
}

interface Props {
    activePage: number;
    itemsCountPerPage: number;
    onChange: (page: number) => void;
    pageRangeDisplayed: number;
    totalItemsCount: number;
}

interface ButtonProps {
    toPage: number;
    label: string | number;
}

function getPaginationItems({
    activePage,
    firstPage,
    lastPage,
    pageRange,
}: GetPaginationItemsProps) {
    let countItems = 0;
    const items: number[] = [];

    // Keep adding items till the pageRange limit has been reached
    // Subtract 2 from pageRange because those spots are taken by the first and last page
    for (let i = 1; items.length < pageRange - 2; i += 1) {
        const decrement = activePage - i;
        const increment = activePage + i;
        // Adds the currently active page to the array
        if (
            items.indexOf(activePage) === -1 &&
            activePage !== firstPage &&
            lastPage !== activePage
        ) {
            items.push(activePage);
        }

        // Adds page of lower number to the array
        if (decrement > firstPage) {
            items.push(decrement);
        }

        // Adds page of higher number to the array
        if (increment < lastPage) {
            items.push(increment);
        }
    }

    // Sort the numbers ascending
    const itemsSorted = items.sort((a, b) => a - b);
    // Insert first page
    itemsSorted.splice(0, 0, firstPage);
    // Insert last page
    itemsSorted.splice(items.length, 0, lastPage);

    return itemsSorted;
}

const Pagination: React.FunctionComponent<Props> = ({
    activePage,
    itemsCountPerPage,
    onChange,
    pageRangeDisplayed,
    totalItemsCount,
}) => {
    const lastPage = Math.ceil(totalItemsCount / itemsCountPerPage);
    const pageRange = Math.min(pageRangeDisplayed, lastPage);

    const renderButton = ({ toPage, label }: ButtonProps) => {
        // in some cases when going back in the list you'll get repeated keys in-transition
        const randomKey = Math.floor(Math.random() * Math.floor(1000));

        return (<button
            type="button"
            key={`pagination-button-${toPage}-${randomKey}`}
            className={joinClassNames(
                styles.button,
                toPage === activePage && styles.isActive,
                typeof label === 'string' && styles.isTextAsLabel,
            )}
            onClick={() => {
                onChange(toPage);
            }}
        >
            {label}
        </button>)
    };

    const paginationItems = getPaginationItems({
        activePage,
        firstPage: 1,
        lastPage,
        pageRange,
    });

    const { t } = useTranslation();
    const next = t('pagination.next') + " >";
    const prev = "< " + t('pagination.prev');

    return (
        <section className={styles.pagination}>
            <div className={joinClassNames(styles.paginationControls, styles.paginationControlsLeft)}>
                {(lastPage >= 11 && activePage !== 1 ) &&
                    renderButton({ toPage: 1, label: "<<" })}
                {activePage > 1 &&
                    renderButton({ toPage: activePage - 1, label: prev })}
            </div>
            <div className={styles.paginationButtonWrapper}>
                <div className={styles.paginationButton}>
                    {paginationItems.map((num, index) => {
                        const nextNum = paginationItems[index + 1];
                        // Next num differs more than 1, than show ellipses
                        if (nextNum && Math.abs(num - nextNum) > 1) {
                            return (
                                <React.Fragment key={`pagination-buttons-${index}`}>
                                    {renderButton({ toPage: num, label: num })}
                                    <span className={styles.ellipses} />
                                </React.Fragment>
                            );
                        }
                        return renderButton({ toPage: num, label: num });
                    })}
                </div>
            </div>
            <div className={joinClassNames(styles.paginationControls, styles.paginationControlsRight)}>
                {activePage < lastPage &&
                    renderButton({ toPage: activePage + 1, label: next })}
                {(lastPage >= 11 && lastPage !== activePage ) &&
                    renderButton({ toPage: lastPage, label: ">>" })}
            </div>
        </section>
    );
};

export default Pagination;
