import * as React from 'react';
import styles from './ClubContent.scss';
import Modal from 'react-modal';
import ClubContentAssets from './components/ClubContentAssets';
import ClubContentInfo from './components/ClubContentInfo';
import ClubContentModal from './components/ClubContentModal';

interface Props {
	club: {
		id: number;
		name: string;
		subtitle: string;
		content: string;
		specialities: string[];
		assets: {
			type: string;
			caption?: string;
			video?: string;
			uri?: string;
			link?: string;
			images?: string[];
			source?: string | string[];
		}[];
	};
	route: string;
	page?: string;
	customStyle?: string;
}

const modalStyling = {
	overlay: {
		zIndex: '10',
		backgroundColor: 'rgba( 0, 0, 0, 0.4)',
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
};

// TODO: fix this super ugly hack
if (process.env.NODE_ENV === 'test') {
	let appElement = document.createElement('div');
	appElement.setAttribute('id', 'app');
	document.body.appendChild(appElement);
}

Modal.setAppElement('#app');

const ClubContent: React.FunctionComponent<Props> = ({
	club,
	page,
	route = '/',
	customStyle,
}) => {
	const [ modalIsOpen, setModal ]: any = React.useState(false);
	const [ modalContent, setModalContent ]: any = React.useState('');

	return (
		<div className={styles.clubContentWrapper}>
			<ClubContentModal
				openModal={modalIsOpen}
				closeModal={() => setModal(false)}
				contentLabel="Expanded image view"
				style={modalStyling}
				content={modalContent}
				club={club}
			>
				<button
					onClick={() => setModal(false)}
					className={styles.modalClose}
				/>
			</ClubContentModal>
			<ClubContentAssets
				modalState={modalIsOpen}
				toggleModal={setModal}
				setModalContent={setModalContent}
				assets={club.assets}
				route={route}
			/>
			<ClubContentInfo
				club={club}
				page={page}
				customStyle={customStyle}
			/>
		</div>
	);
};

export default ClubContent;
