import * as React from 'react';
import styles from './WidgetWrapper.scss';
import joinClassNames from '../../../utils/join-class-names';

interface Props {
	children: React.ReactType | React.ComponentType | React.ReactNode;
	className?: string;
	title: string;
	titleEnd?: string;
	ratingFirst?: number;
	ratingLast?: number;
}

const WidgetWrapper: React.FunctionComponent<Props> = ({
	children,
	className = null,
	title,
	titleEnd,
	ratingFirst,
	ratingLast,
}) => {
	const renderTitle = () => {
		return titleEnd ? (
			<div>
				{title} <span>{ratingFirst}{' '}</span>
				{titleEnd} <span>{ratingLast}</span>
			</div>
		) : (
			title
		);
	};

	return (
		<div className={joinClassNames(styles.root, className)}>
			<header>{renderTitle()}</header>
			<div className={styles.content}>{children}</div>
		</div>
	);
};

export default WidgetWrapper;
