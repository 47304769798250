import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '~source/ui/components';
import { ModuleFieldsWarranty } from '~source/core/interfaces';
import s from './WarrantyModule.scss';

interface Props {
    fields: ModuleFieldsWarranty;
}

const WarrantyModule: React.FunctionComponent<Props> = ({ fields }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <div className={s.root}>
                <div className={s.label}>{t('usps.warranty')}</div>
                <ul className={s.list} data-testid="warranty-module">
                    {fields.warranties.map(warranty => (
                        <li key={warranty} className={s.item}>
                            {warranty}
                        </li>
                    ))}
                </ul>
            </div>
        </Container>
    );
};

export default WarrantyModule;
