import * as React from 'react';

import s from './Spinner.scss';
import joinClassNames from '~source/utils/join-class-names';

interface Props {
    className?: string;
    color?: string;
    size?: number;
}

const Spinner: React.FunctionComponent<Props> = ({
    className = null,
    color = '#000',
    size = 30,
}) => {
    return (
        <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 12 12"
            className={joinClassNames(s.root, className)}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Combined-Shape" fill={color}>
                    <path d="M12,6 L11,6 C11,3.23857625 8.76142375,1 6,1 C3.23857625,1 1,3.23857625 1,6 C1,8.76142375 3.23857625,11 6,11 L6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 C9.3137085,0 12,2.6862915 12,6 Z"></path>
                </g>
            </g>
        </svg>
    );
};

export default Spinner;
