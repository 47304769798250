import { useTranslation } from 'react-i18next';
import toQueryString from '../utils/to-query-string';

const {
    ETCP_SITE_CONFIG: { apiVersion, apiSite },
    ETCP_PIPELINE_CONFIG: { apiUrl },
} = window;

const urlCms = `${apiUrl}v${apiVersion}/site/${apiSite}/`;
const urlPackages = `${apiUrl}v${apiVersion}/packages/football/`;

function createEndpoint(
    baseUrl: string,
    resource: string,
    query: object = {},
    locale: string,
) {
    if (typeof resource === 'undefined' || typeof resource !== 'string') {
        throw Error(
            'Resource is undefined or not of type string in createCmsEndpoint',
        );
    }
    if (typeof query !== 'object') {
        throw Error('Query is not of type object in createCmsEndpoint');
    }
    return `${baseUrl}${resource}?${toQueryString({
        ...query,
        culture: locale,
    })}`;
}

export default function useEndpoint() {
    const { i18n } = useTranslation();
    const locale = i18n.language.toLowerCase();

    return {
        createCmsEndpoint(resource: string, query?: object) {
            return createEndpoint(urlCms, resource, query, locale);
        },
        createPackagesEndpoint(resource: string, query?: object) {
            return createEndpoint(urlPackages, resource, query, locale);
        },
    };
}
