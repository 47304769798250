import { EventLevel1 } from '~source/core/models/event';

const eventMock = `{
    "system":{
       "id":"f3049786-6a5c-49dd-ad27-a89b056356c7",
       "name":"Tottenham Hotspur vs Bournemouth",
       "urlSegment":"tottenham-hotspur-vs-bournemouth",
       "type":"Content",
       "createdAt":"2019-10-25T10:15:18.093Z",
       "editedAt":"2019-10-25T10:15:18.093Z",
       "revision":2,
       "contentType":"event",
       "locale":"en-gb"
    },
    "fields":{
       "systemId":"3f36dbd8-00e7-4dbe-88d0-36f5c8a28f25",
       "eventName":"Tottenham Hotspur vs Bournemouth",
       "eventType":"TeamSports",
       "dateAndTime":"30-11-2019",
       "endDateAndTime":"",
       "bookingStartAt":"23-11-2019",
       "bookingStopAt":"07-12-2019",
       "dateMinimumStartAt":"29-11-2019",
       "dateMinimumEndAt":"30-11-2019",
       "venue":{
          "system":{
             "id":"cf24ba6c-1d35-48f0-b7d2-9dec278b2c83",
             "name":"Tottenham Hotspur Stadium",
             "urlSegment":"tottenham-hotspur-stadium",
             "type":"Content",
             "createdAt":"2019-10-25T10:08:03.94Z",
             "editedAt":"2019-10-28T14:56:30.007Z",
             "revision":5,
             "contentType":"venue",
             "locale":"en-gb"
          },
          "fields":{
             "systemId":"51ec0029-2720-4afe-a26f-81a4595af707",
             "venueName":"Tottenham Hotspur Stadium",
             "countryCode":"GB",
             "timeZone":"",
             "latitude":0,
             "longitude":0,
             "city":"London",
             "enrichment_text":""
          }
       },
       "serie":{
          "system":{
             "id":"b77dbed5-62fe-42fe-8bf6-73122ce7ff89",
             "name":"Premier League 2019 / 2020",
             "urlSegment":"premier-league-2019-2020",
             "type":"Content",
             "createdAt":"2019-10-25T10:08:20.113Z",
             "editedAt":"2019-10-28T14:57:18.413Z",
             "revision":5,
             "contentType":"serie",
             "locale":"en-gb"
          },
          "fields":{
             "systemId":"88fbe015-2459-447e-b5ea-de218fbcfc79",
             "serieName":"Premier League 2019 / 2020",
             "enrichment_text":""
          }
       },
       "homeTeam":{
          "system":{
             "id":"56aa9a28-90db-4c05-b543-d92dce96bb3f",
             "name":"Tottenham Hotspur",
             "urlSegment":"tottenham-hotspur",
             "type":"Content",
             "createdAt":"2019-10-25T10:11:06.4Z",
             "editedAt":"2019-10-25T10:11:06.4Z",
             "revision":2,
             "contentType":"team",
             "locale":"en-gb"
          },
          "fields":{
             "systemId":"50c49b89-5435-42dc-9aed-a9751f5b1155",
             "teamName":"Tottenham Hotspur",
             "shortName":"Spurs",
             "abbrevation":"",
             "color":"#F1F1F1",
             "countryCode":"UK",
             "enrichment_text":""
          }
       },
       "awayTeam":{
          "system":{
             "id":"cc3fac04-a311-487e-80a9-6c73929be0b1",
             "name":"Bournemouth",
             "urlSegment":"bournemouth",
             "type":"Content",
             "createdAt":"2019-10-25T10:08:58.783Z",
             "editedAt":"2019-10-25T10:08:58.783Z",
             "revision":2,
             "contentType":"team",
             "locale":"en-gb"
          },
          "fields":{
             "systemId":"45409edd-6953-45b7-85be-98cda62a004b",
             "teamName":"Bournemouth",
             "shortName":"Bournemouth",
             "abbrevation":"",
             "color":"#A00000",
             "countryCode":"UK",
             "enrichment_text":""
          }
       },
       "basePrice":"119.00",
       "confirmed":false
    }
 }`;

const mock = new EventLevel1(JSON.parse(eventMock));

export default mock;
