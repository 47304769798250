import * as React from 'react';
import styles from './Card.scss';

interface Props {
    children: React.ReactType | React.ComponentType | React.ReactNode;
    customStyles?: string;
}

const Card: React.FunctionComponent<Props> = ({ children, customStyles }) => {
    return (
        <div
            className={`${styles.card} ${customStyles} `}
            data-testid="ui-card"
        >
            {children}
        </div>
    );
};

export default Card;
