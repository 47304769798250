import * as React from 'react';
import InitContext from '~source/context/init';

interface Props {
	width?: string;
	height?: string;
	logoUrl: string;
}

const AppLogo: React.FunctionComponent<Props> = ({
	width = '163',
	height = '59',
	logoUrl
}) => {
	return 	<img data-testid="app-logo-img" src={logoUrl} alt={'logo'} width={width} height={height}/>
};

export default AppLogo;
