import * as React from 'react';
import styles from './Badge.scss';

interface Props {
	message: string;
}

const Badge: React.FunctionComponent<Props> = ({ message }) => (
	<div className={styles.badge}>
		<div className={styles.badgeContent} data-testid="badge" dangerouslySetInnerHTML={{ __html: message }} />
	</div>
);

export default Badge;
