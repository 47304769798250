import * as React from 'react';
import { useTranslation } from 'react-i18next';
import usePortal from 'react-useportal';
import { Link } from 'react-router-dom';

import joinClassNames from '../../../utils/join-class-names';
import useDebounce from '../../../hooks/useDebounce';
import styles from './SearchInput.scss';

interface ResultProps {
    title: string;
    url: string;
}

interface Props {
    className?: string;
    placeholder: string;
    limitResults?: number;
}

const SearchInput: React.FunctionComponent<Props> = ({
    className,
    placeholder,
    limitResults = 7,
}) => {
    const { t } = useTranslation();
    const inputElement = React.useRef<HTMLInputElement>(null);
    const [query, setQuery] = React.useState('');
    const [activeListIndex, setActiveListIndex] = React.useState(-1);
    const [results, setResults] = React.useState<ResultProps[]>([]);
    const { openPortal, closePortal, isOpen, Portal } = usePortal();
    const debouncedQuery = useDebounce(query, 300);

    /**
     * TODO: Replace this fake 'fetchResults' with a real API call
     */
    const fetchResults = () => {
        const countResults = Math.floor(Math.random() * limitResults);
        const results: ResultProps[] = [];

        for (let i = 0; countResults > i; i++) {
            results.push({
                title: 'Search Result Title',
                url: '/',
            });
        }
        // Reset the index after new results are in
        setActiveListIndex(-1);
        setResults(results);
    };

    const handleChange = event => {
        setQuery(event.target.value);
    };

    const handleKeyDown = event => {
        if (results.length < 1) return;

        switch (event.keyCode) {
            // Up
            case 38:
                if (activeListIndex <= 0) {
                    setActiveListIndex(results.length - 1);
                } else {
                    setActiveListIndex(activeListIndex - 1);
                }
                return;
            // Down
            case 40:
                if (activeListIndex === results.length - 1) {
                    setActiveListIndex(0);
                } else {
                    setActiveListIndex(activeListIndex + 1);
                }
                return;
            // Enter key
            case 13:
                // Do not submit form
                event.preventDefault();
                event.stopPropagation();

                if (activeListIndex !== -1) {
                    // Go to url of selected result
                    window.location.href = results[activeListIndex].url;
                }
                return;
            default:
                return;
        }
    };

    const renderResults = () => {
        if (!inputElement || !inputElement.current) return null;

        if (!query || query.length < 1) {
            return null;
        }

        const { pageYOffset, pageXOffset } = window;
        const {
            top,
            left,
            height,
            width,
        } = inputElement.current.getBoundingClientRect();
        const dropdownStyle = {
            top: `${top + pageYOffset + height}px`,
            left: `${left + pageXOffset}px`,
            width: `${width}px`,
            position: 'absolute' as 'absolute',
        };

        // No results
        if (results.length < 1) {
            return (
                <div className={styles.dropdown} style={dropdownStyle}>
                    <div className={styles.noResultsText}>
                        {t('search.noResultsText')}
                    </div>
                </div>
            );
        }

        return (
            <ul className={styles.dropdown} style={dropdownStyle}>
                {results.map((result, index) => {
                    const isActive = activeListIndex === index;
                    return (
                        <li
                            key={`${result.title}_${index}`}
                            className={styles.dropdownItem}
                        >
                            <Link
                                className={joinClassNames(
                                    styles.link,
                                    isActive && styles.isActive,
                                )}
                                to={result.url}
                            >
                                {result.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        );
    };

    React.useEffect(() => {
        if (debouncedQuery) {
            fetchResults();
        } else {
            setResults([]);
        }
    }, [debouncedQuery]);

    const renderedResults = renderResults();

    return (
        <>
            <input
                className={joinClassNames(
                    className,
                    renderedResults && isOpen && styles.hasDropDown,
                )}
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
                value={query}
                onFocus={openPortal}
                onClick={openPortal}
                onBlur={closePortal}
                onKeyDown={handleKeyDown}
                ref={inputElement}
                spellCheck={false}
            />
            {isOpen && <Portal>{renderedResults}</Portal>}
        </>
    );
};

export default SearchInput;
